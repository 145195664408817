import React, { useLayoutEffect, useRef } from 'react';
import { LightboxInset } from './LightboxInset.js';
import { isTouchDevice } from './isTouchDevice.js';

/**
 * Renders the lightbox.
 */
export const LightboxCanvas = () => {
  const lightboxRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    (async () => {
      if (isTouchDevice() && lightboxRef.current && document.fullscreenEnabled) {
        try {
          await lightboxRef.current.requestFullscreen({ navigationUI: 'hide' });
        } catch (err) {
          console.log(`Lightbox: fullscreen request was denied.`);
        }
      }
    })();
  }, []);

  return (
    <div ref={lightboxRef} className="lightbox">
      <LightboxInset />
    </div>
  );
};
