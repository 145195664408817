import React from 'react';
import { LightboxImage } from './LightboxImage.js';
import { LightboxSpinner } from './LightboxSpinner.js';
import { LightboxTitle } from './LightboxTitle.js';
import { LightboxControls } from './LightboxControls.js';

/**
 * Renders the "inset" component which contains
 * the displayed image and the controls.
 */
export const LightboxInset = () => {
  return (
    <div className="inset fullscreen">
      <LightboxImage />
      <LightboxSpinner />
      <LightboxTitle />
      <LightboxControls />
    </div>
  );
};
